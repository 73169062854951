import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Page from '../layouts/page'
import Title from '../components/layout/title'
import Img from 'gatsby-image'

const Talks = ({ data }) => {
  return (
    <Page>
      <Helmet
        bodyAttributes={{
          class: 'bg-grey'
        }}
      >
        <html lang="en" />

        <title>Talks | Nathan Dunn - Software Developer, Consultant and Speaker</title>
        <meta name="description" content="Nathan Dunn has delivered a number of technical talks at several local user groups over the years on topics including PHP, Laravel and Vue.js." />
      </Helmet>

      <Title>Talks</Title>

      <p>I have delivered a number of technical talks at several local user groups over the years.</p>

      <h3 className="mt-4 text-lg font-bold text-olive">PHP North East</h3>
      <ul>
        <li>Six Simple Steps for Super Sentiment Analysis - March 2016</li>
        <li>Readable Requests and Responses with RAML - January 2017</li>
        <li>Building a PHP SDK with HTTPlug - March 2018</li>
        <li>On the Horizon - September 2018</li>
        <li>Getting to grips with Laravel collections - July 2019</li>
      </ul>
      <h3 className="mt-4 text-lg font-bold text-olive">
        JavaScript North East
      </h3>
      <ul>
        <li>An Introduction to the Serverless Framework - July 2018</li>
        <li>A Point of Vue - February 2019</li>
      </ul>

      <p className="mt-4">If you'd like me to speak at your event, feel free to <a href="https://twitter.com/nthndnn">get in touch</a>.</p>

      <Img 
        fluid={data.header.childImageSharp.fluid} 
        className="mt-5" 
        alt="Nathan speaking at PHP North East in July 2019"
        draggable={false}
      />
    </Page>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { regex: "/talks/" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 550, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Talks